import { browser } from '$app/environment';
import type Keycloak from 'keycloak-js';
import { PUBLIC_KEYCLOAK_CLIENT_ID, PUBLIC_KEYCLOAK_URL, PUBLIC_KEYCLOAK_REALM } from '$env/static/public';

let keycloak: Keycloak | null = null;

/**
 * Initializes the Keycloak instance.
 * @returns {Promise<Keycloak | null>} The initialized Keycloak instance or null if not in the browser.
 */
export async function initializeKeycloak(): Promise<Keycloak | null> {
	if (browser && !keycloak) {
		const KeycloakConstructor = (await import('keycloak-js')).default;
		keycloak = new KeycloakConstructor({
			url: PUBLIC_KEYCLOAK_URL,
			realm: PUBLIC_KEYCLOAK_REALM,
			clientId: PUBLIC_KEYCLOAK_CLIENT_ID
		});

		try {
			await keycloak.init({
				checkLoginIframe: false,
				responseMode: 'query'
			});
		} catch (error) {
			console.error('Keycloak initialization failed:', error);
			keycloak = null;
		}
	}
	return keycloak;
}

export default keycloak;
