import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import { initializeKeycloak } from '$lib/keycloak';
import type Keycloak from 'keycloak-js';

interface AuthState {
	isAuthenticated: boolean;
	username: string | null;
	isAdmin: boolean;
	isInitialized: boolean;
}

function createAuthStore() {
	const { subscribe, set, update } = writable<AuthState>({
		isAuthenticated: false,
		username: null,
		isAdmin: false,
		isInitialized: false
	});

	let keycloakInstance: Keycloak | null = null;

	return {
		subscribe,
		setAuth: (state: Omit<AuthState, 'isInitialized'>) =>
			update(current => ({ ...current, ...state, isInitialized: true })),

		getToken: (): string | null => {
			return keycloakInstance?.token || null;
		},

		login: async (locale: string = 'en') => {
			if (browser) {
				if (!keycloakInstance) {
					keycloakInstance = await initializeKeycloak();
				}
				if (keycloakInstance) {
					try {
						await keycloakInstance.login({ locale });
						updateAuthState(keycloakInstance);
					} catch (error) {
						console.error('Login failed:', error);
					}
				}
			}
		},

		logout: async () => {
			if (browser) {
				if (!keycloakInstance) {
					keycloakInstance = await initializeKeycloak();
				}
				if (keycloakInstance) {
					try {
						await keycloakInstance.logout();
						set({ isAuthenticated: false, username: null, isAdmin: false, isInitialized: true });
					} catch (error) {
						console.error('Logout failed:', error);
					}
				}
			}
		},

		init: async () => {
			if (browser) {
				keycloakInstance = await initializeKeycloak();
				if (keycloakInstance) {
					const isAuthenticated = keycloakInstance.authenticated || false;
					const username = keycloakInstance.tokenParsed?.preferred_username || null;
					const isAdmin = checkAdminRole(keycloakInstance);
					set({ isAuthenticated, username, isAdmin, isInitialized: true });
				} else {
					console.error('Keycloak is not initialized.');
				}
			}
		}
	};
}

function checkAdminRole(kc: Keycloak): boolean {
	const roles = kc.tokenParsed?.realm_access?.roles || [];
	return roles.includes('admin');
}

function updateAuthState(kc: Keycloak) {
	const isAuthenticated = kc.authenticated || false;
	const username = kc.tokenParsed?.preferred_username || null;
	const isAdmin = checkAdminRole(kc);

	authStore.setAuth({ isAuthenticated, username, isAdmin });
}

export const authStore = createAuthStore();
